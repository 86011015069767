import React from 'react';

const Dashboard = () => {
    return (
        <div className='flex justify-center'>
        <div className='grid grid-cols-4 gap-2'>
            <div className='border rounded-lg p-2 m-4 border-slate-600'>
                <p>Chart</p>
            </div>
            <div className='border rounded-lg p-2 m-4 border-slate-600'>
                <p>Chart</p>
            </div>
            <div className='border rounded-lg p-2 m-4 border-slate-600'>
                <p>Chart</p>
            </div>
            <div className='border rounded-lg p-2 m-4 border-slate-600'>
                <p>Chart</p>
            </div>
            <div className='border rounded-lg p-2 m-4 border-slate-600'>
                <p>Chart</p>
            </div>
            <div className='border rounded-lg p-2 m-4 border-slate-600'>
                <p>Chart</p>
            </div>
            
        </div>
        </div>
    );
};

export default Dashboard;